<script>
import logoImg from "@/assets/new_logo.png";
export default {
  data() {
    return {
      logo: logoImg,
    };
  },
  created() {
    this.$store.commit("Nav/SET_PAGE", "about-us");
  },
};
</script>
<template>
  <div class="privacy-policy my-container">
    <div class="left">
      <h1 class="title">About Us</h1>
      <p>
        We are immensely grateful for the continued support and interest in our
        service so many of you have demonstrated over the years. It's not enough
        to just say 'thank you' (or more precisely, ありがとうございました!) We
        actually wish to demonstrate our gratitude to our most loyal customers -
        by offering you a way to receive something more for your patronage. Our
        rewards shop will be a place where you can check your points balance,
        and redeem them - once you accrue enough, for cool and interesting items
        from Japan that we feel collectors will appreciate. We will update our
        catalog of these items on a regular basis. Cheers!
      </p>
      <p class="sub-text">
        PJ, Mayumi, Haruka, and the rest of our team at Kupiku.
      </p>
    </div>
    <div class="right">
      <div class="logo-img">
        <img :src="logo" alt="" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/styles/_components.scss";
@import "@/assets/styles/_mixins.scss";

.privacy-policy {
  font-family: var(--open-sans);
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    padding-right: 2rem;
  }
  .sub-text {
    font-weight: 50;
    font-family: var(--marker);
    color: var(--color-grey-dark-2);
  }
  @include respond(tab-p) {
  }
  @include respond(tab-l) {
  }
  @include respond(phone) {
    display: flex;
    flex-direction: column;
    .left {
      padding: 0;
      margin-top: 2rem;
    }
    .right {
      order: 0;
    }
  }
}
.my-container {
  padding: 8.8rem 9rem;
  @include respond(phone) {
    padding: 8.1rem 6rem;
  }
}
.title {
  font-family: var(--marker);
  margin-bottom: 3.3rem;
  align-self: left;
  font-size: 3.8rem;
  font-weight: 600;
  color: var(--color-lapis-blue);
}
.policy-heading {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 2.1rem;
}

p {
  font-size: 1.7rem;
  max-width: 60ch;
  margin-bottom: 2.8rem;

  @include respond(phone) {
    max-width: 100ch;
  }
}
</style>
